.bgColor {
    background-color: #ff6b06;
}
.logo {
    width: 100px;
    height: 100px;
    overflow: hidden;
}
.logo img {
    width: 100%;
    height: 100%;
}
.top-img {
    width: 100%;
    height: 90vh;
    margin-top: 2px;
}
.slider-img {
    overflow: hidden;
    object-fit: cover;
    height: 60vh;
}
.slider-img img {
    width: 100%;
    height: auto;
}
.about-us {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 78%;
}
.contact-us {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 50%;
}
.service {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 56%;
}
.h1 {
    font-size: 30px !important;
}
.h2 {
    font-size: 20px !important;
}
.h3 {
    font-size: 16px !important;
}
.h4 {
    font-size: 12px !important;
}
.fwb {
    font-weight: 700 !important;
}
.fwn {
    font-weight: 400 !important;
}
.padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.about-img {
    width: 90%;
    height: 400px;
    overflow: hidden;
}
.gallery-img {
    width: 90%;
    height: 400px;
    overflow: hidden;
    object-fit: cover;
}
.gallery-img img {
    width: 100%;
    height: 100%;
}
.about-img img {
    width: 100%;
    height: 100%;
}
.bg-img {
    height: 250px;
    width: 100%;
    filter: drop-shadow(8px 8px 10px darkGray)
}
.profile-img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
}
.textDecoration {
    text-decoration: none !important;
}
.white { 
    color: #fff !important;
}
.animation {
    animation-delay: 500ms !important;
}

@media screen and (max-width: 600px) {
    .about-img {
        width: 100% !important;
        height: auto !important;
    }
    .gallery-img {
        width: 100% !important;
    }
    .top-img {
        height: auto;
    }
    .about-us {
        object-position: 13%;
    }
    .padding {
        padding-left: unset !important;
        padding-right: unset !important;
    }
}